import httpClient from "../../utilities/http-client";
import { LegalEntity } from "../../models/circuito/LegacyEntity";
import { useState } from "react";
import { LegacyMarket } from "../../models/market/LegacyMarket";


export const marketsList = async () => {
	try {
		const { data } = await httpClient.get<LegacyMarket[]>("/activities");
		return data;
	} catch (e: any) {
		throw  e;
	}
};

export const marketsListSearch = async (search:string) => {
	try {
		const { data } = await httpClient.get<LegacyMarket[]>(`/activities/${search}`);
		return data;
	} catch (e: any) {
		throw  e;
	}
};

export const myMarketsList = async () => {
	try {
		const { data } = await httpClient.get<LegacyMarket[]>(`/activities/my`);
		return data;
	} catch (e: any) {
		throw  e;
	}
};

export const mymarketDelete = async (marketId:string) => {
	try{
		const { data } = await httpClient.delete<LegacyMarket[]>(`/activities/${marketId}`);
		return data;
	}catch(e:any){
		throw e;
	}
}