import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  Card,
  CardContent,
  TextField,
  Button,
  Grid,
  Paper,
  IconButton,
  styled,
} from '@mui/material';
import PrintIcon from '@mui/icons-material/Print';
import { BLACK, GRAY_LIGHT, GRAY_LIGHT_RGB_BORDER, GRAY_MAIN, WHITE } from '../theme/colors';
import { useTranslationContext } from '../hooks/translations/translationsProvider';
import settings from '../utilities/settings';
import { useWaiter } from '../hooks/waiter/WaiterProvider';
import { delay } from '../utilities/delay';
import { dashboard } from '../hooks/actions/dashboard';
import { useWeb3Context } from '@w3connector/web3provider';
import { validateAddress } from '../helpers/ethers';
import { IWithdrawRequestModel, IWithdrawResponseModel } from '../models/withdraw';
import { withdrawRequest } from '../hooks/actions/withdraw';
import WithdrawResponse from './Withdraw/WithDrawResponse';
import CheckBoxTermsAndConditions from './CheckBoxTermsAndConditions';

const FormCustom = styled('form')(({theme})=>({
    width:'100%',                                      
    [theme.breakpoints.up('md')]: {
        width:'80%',
    },
}))

const ResponseBox = styled(Box)(({theme})=>({
    width:'100%',
    marginTop:"30px",                                      
    [theme.breakpoints.up('md')]: {
        width:'80%',
    },
}))

interface WithdrawFormProps {
    setStatus: (status: boolean) => void;//React.Dispatch<React.SetStateAction<boolean>>;
    status: boolean;
};

const WithdrawForm: React.FC<WithdrawFormProps> = ({setStatus, status}) => {
    const [response, setResponse] = useState<IWithdrawResponseModel | null>(null);
    const {t} = useTranslationContext();
    const {  
        modalOpen,
        connectors,
        web3Error,
        web3Hooks,
        chainName,
        setModalOpen,
        setWeb3Error,
        handleDisconnect } = useWeb3Context();
    const [amountAvaiable, setAmountAvaible] = useState<number>(0);

    const [amount, setAmount] = useState('');
    const [errorAmount, setErrorAmount] = useState(false);
    const [errorAmountMessage, setErrorAmountMessage] = useState('')

    const [address, setAddress] = useState('');
    const [errorAddress, setErrorAddress] = useState(false);
    const [errorAddressMessage, setErrorAddressMessage] = useState('')

    const [isSended, setIsSended] = useState(false);
    const [mainErrorMex, setMainErrorMex]=useState<string | null>(null)

    const {setWaiterView} = useWaiter();

    const [isChecked, setIsChecked] = useState(false); 

    useEffect(()=>{
        setWaiterView(true)
        delay(500).finally(()=>{
            dashboard()
                .then(resp => {
                    setAmountAvaible(resp.balance)
                })
                .catch(error => {
                    // initLoad.current = false
                    console.log(error)
                })
            setWaiterView(false)
        })
    },[])

    const handleInputAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        
        setErrorAmount(false);
        setErrorAmountMessage('');
        const value = e.target.value;
        const regex = /^\d*\.?\d{0,2}$/;

        if(value === ''){
            setAmount('');
            setErrorAmount(false);
            console.log( value )
            return;
        }

        if (value.includes('.')) {
            const [integerPart, decimalPart] = value.split('.');
            if (decimalPart.length > 2) {
                setErrorAmount(false);
                return;
            }
        }

        if(!regex.test(value)){
            setErrorAmountMessage(t("Inserisci un importo valido"))
            setErrorAmount(true)
        }

        if (parseFloat(value) > 0) {
            if(parseFloat(value) > amountAvaiable){
                setAmount(amountAvaiable.toFixed(2))
                return;
            }
            setAmount(value);
            setErrorAmount(false);
            
        } else {
            setErrorAmountMessage(t("Devi prelevare minimo: ") + parseFloat(settings.WithdrawTokens.MinAmount).toFixed(2) + " Asso")
            setErrorAmount(true);
            return;
        }
    };

    const handleInputAddressChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        
        setErrorAddress(false);
        setErrorAddressMessage('');
        const value = e.target.value;
        const regex = /^[a-zA-Z0-9]*$/;

        if(value === ''){
            setAddress('');
            setErrorAddress(false);
            return;
        }
        if(!regex.test(value)){
            setErrorAddressMessage(t("Inserisci solo lettere e numeri"))
            setErrorAddress(true)
            return;
        }
        setAddress(value);
        setErrorAddress(false);
    };

    const handleBlurAmount = () => {
        if (!errorAmount && amount) {
            if(parseFloat(amount) < parseFloat(settings.WithdrawTokens.MinAmount))
            {
                setErrorAmountMessage(t("Devi prelevare minimo: ") + parseFloat(settings.WithdrawTokens.MinAmount).toFixed(2) + " Asso")
                setErrorAmount(true);
                setAmount('');
                return;
            }
            const newAmount = parseFloat(amount).toFixed(2)
            setAmount(newAmount);
            setErrorAmount(false);
        }
    };

    const handleBlurAddress = () => {
        if (address!=='') {
            
            if(!validateAddress(address)){
                setErrorAddress(true);
                setErrorAddressMessage("L'indirizzo del wallet che hai inserito non è corretto!");
            }else{
                setErrorAddress(false);
                setErrorAddressMessage('');
            }
                
        }
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setIsSended(true);
        setWaiterView(true);
        setMainErrorMex(null);

        if(!errorAmount && !errorAddress && address !== '' && parseFloat(amount) >= parseFloat(settings.WithdrawTokens.MinAmount) && validateAddress(address) && isChecked)
        {
            const newReq:IWithdrawRequestModel = {
                amount: parseFloat(amount),
                address: address,
                acceptTerms: isChecked
            }
            try{
                const withdraw = await withdrawRequest(newReq)
                setResponse(withdraw);
            }catch(e:any){
                console.log(e);
                setResponse(null);
                setMainErrorMex(e.data)
                setIsSended(false);
            }
            setWaiterView(false);
        }
        setStatus(!status); // reload delle ultime transazioni
    };

    return (
        <Box sx={{width:'100%'}}>
            <Paper sx={{ width: '100%', display: 'flex', minHeight:'350px', border: 1, borderColor: 'divider', boxShadow:5}}>
                
                <Box sx={{ flexGrow: 1, p: 3, position: 'relative' }}>
                    {response && (
                        <IconButton
                            onClick={() => window.print()}
                            sx={{ position: 'absolute', top: 16, right: 16 }}
                        >
                            <PrintIcon />
                        </IconButton>
                    )}

                    <Card sx={{ backgroundColor: 'transparent', boxShadow: 'none' }}>
                        <CardContent 
                        sx={{
                            display:'flex',
                            justifyContent:'center',
                            alignItems:'center',
                            width:'100%',
                            padding:'24px 0 0 0',
                            flexDirection:'column'
                        }}>
                            <Typography variant="h4" gutterBottom  color={BLACK} >
                                {t("Preleva Asso")}
                            </Typography>

                            { !response &&
                                <FormCustom onSubmit={handleSubmit}>
                                    <Grid container spacing={2} sx={{marginTop:'30px'}}>
                                        <Grid item xs={12}>
                                            <Typography color={BLACK} textAlign={'left'} mb={2}>
                                                <strong>{t("Importo disponibile: ")} 
                                                {amountAvaiable.toFixed(2)} Asso</strong>
                                            </Typography>
                                        </Grid>

                                        {web3Hooks && web3Hooks.isActive ?
                                            <Grid item xs={12}>
                                                <Typography color={GRAY_MAIN} textAlign={'left'} mb={2} sx={{fontSize:"0.8rem"}}>
                                                🟢 {t("Il tuo wallet è connesso con l'indirizzo: ")}
                                                    <strong>{web3Hooks.account}</strong>
                                                </Typography>
                                                <Button 
                                                    onClick={() => {handleDisconnect()}}
                                                    variant="contained"
                                                    sx={{fontSize:"0.7rem", width:'200px', color:WHITE, marginBottom:'50px'}}>
                                                        {t("Disconnetti Wallet")}
                                                </Button>
                                            </Grid>:
                                            <Grid item xs={12}>
                                                <Typography color={GRAY_MAIN} textAlign={'left'} mb={2} sx={{fontSize:"0.8rem"}}>
                                                🔴 <strong>{t("Il tuo wallet è disconnesso")}</strong>
                                                </Typography>
                                            </Grid>
                                        }

                                        <Grid item xs={12}>
                                            <Grid container>
                                                <Grid item xs={12} sm={9} md={10}>
                                                    <TextField
                                                        fullWidth
                                                        label={t("Incolla l'indirizzo di prelievo o connetti il tuo wallet e importalo")}
                                                        name="address"
                                                        onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {(handleInputAddressChange(evt))}}
                                                        onClick={() => {
                                                            setErrorAddress(false)
                                                        }}
                                                        onBlur={() => handleBlurAddress()} // settare il controllo di indirizzo
                                                        value={address}
                                                        error={errorAddress}
                                                        disabled={isSended}
                                                        helperText={
                                                            errorAddress ? errorAddressMessage : t('Indirizzo Wallet')
                                                        }
                                                        sx={{
                                                            '& .MuiOutlinedInput-notchedOutline':{
                                                                borderColor: GRAY_LIGHT_RGB_BORDER
                                                            }
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={3} md={2} sx={{
                                                    padding: {
                                                        xs:'0 25px',
                                                        sm:'0 0 0 5px'
                                                    },
                                                    marginTop:{
                                                        xs:'10px',
                                                        sm:'0'
                                                    },
                                                    marginButton:{
                                                        xs:'20px',
                                                        sm:'0px'
                                                    }
                                                }}>
                                                    <Button 
                                                        disabled={isSended}
                                                        variant="contained"
                                                        onClick={() => {
                                                            web3Hooks && web3Hooks.isActive 
                                                            ?(
                                                                () => {
                                                                    setAddress(web3Hooks.account!!)
                                                                    setErrorAddress(false);
                                                                    setErrorAddressMessage('');
                                                                }
                                                            )(): setModalOpen(true)
                                                        }}
                                                        sx={{
                                                            height:'60px',  
                                                            color:WHITE,       
                                                            padding: {
                                                                xs: '6x 0px',
                                                            },
                                                            margin: {
                                                                xs: '0',
                                                                sm: '0'
                                                            }
                                                        }}
                                                    >
                                                        {web3Hooks && web3Hooks.isActive ?
                                                        "Importa l'indirizzo" : "Connetti portafogli e importa l'indirizzo"}
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                label={t("Inserisci l'importo che vuoi prelevare")}
                                                name="amount"
                                                onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {(handleInputAmountChange(evt))}}
                                                onClick={() => {
                                                    setAmount('')
                                                    setErrorAmount(false)
                                                }}
                                                onBlur={() => handleBlurAmount()}
                                                value={amount}
                                                error={errorAmount}
                                                // disabled={response.success!}
                                                helperText={
                                                    errorAmount ? errorAmountMessage : t('Importo da prelevare')
                                                }
                                                sx={{
                                                    '& .MuiOutlinedInput-notchedOutline':{
                                                        borderColor: GRAY_LIGHT_RGB_BORDER
                                                    }
                                                }}
                                            />
                                        </Grid>

                                        <CheckBoxTermsAndConditions isChecked={isChecked} setIsChecked={setIsChecked}/>

                                        {address !== '' && parseFloat(amount) >= parseFloat(settings.WithdrawTokens.MinAmount) && 
                                            <>
                                                <Grid item xs={12}>
                                                    <Typography color={GRAY_MAIN} textAlign={'left'} mb={2} sx={{fontSize:"0.8rem"}}>
                                                        {t("L'indirizzo a cui vuoi inviare i fondi è: ")}
                                                        <strong>{address}</strong>
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Typography color={GRAY_MAIN} textAlign={'left'} mb={2} sx={{fontSize:"0.8rem"}}>
                                                        {t("L'importo che vuoi prelevare è: ")} 
                                                        <strong>{parseFloat(amount).toFixed(2)} Asso</strong>
                                                    </Typography>
                                                </Grid>
                                            </>
                                        }

                                        <Grid item xs={12}>
                                            <Button type="submit" variant="contained" disabled={isSended || amount === '' || errorAmount || errorAddress || !validateAddress(address) || !isChecked} 
                                                sx={{
                                                    color:WHITE, 
                                                    border:'0'
                                                }}>
                                                {t("Invia")}
                                            </Button>
                                        </Grid>
                                        {
                                            mainErrorMex && <Grid item xs={12}>
                                                <Typography fontSize="0.75rem" color="red" >
                                                    {mainErrorMex}
                                                </Typography>
                                            </Grid>
                                        }

                                    </Grid>
                                </FormCustom>
                            }

                            { response && 
                                <ResponseBox> {response.refCode}
                                    {
                                        <WithdrawResponse response={response} />
                                    }
                                </ResponseBox> 
                            }
                        </CardContent>
                    </Card>
                </Box>
            </Paper>
        </Box>
    );
};

export default WithdrawForm;
