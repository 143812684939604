import React, { useEffect, useState } from 'react';
import { Box, Button, IconButton, Typography, Modal, Grid } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { LegacyMarket, Market } from '../../models/market/LegacyMarket';
import { useNavigate } from 'react-router-dom'; // Assuming you're using react-router-dom for navigation
import { mymarketDelete, myMarketsList } from '../../hooks/actions/market';
import { useTranslationContext } from '../../hooks/translations/translationsProvider';
import { WHITE } from '../../theme/colors';
import { useWaiter } from '../../hooks/waiter/WaiterProvider';
import AlertDeleteModal from './AlertDeleteModal';

const MarketList: React.FC = () => {
  const [legacyMarkets, setLegacyMarkets] = useState<LegacyMarket[]>([]);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [selectedMarket, setSelectedMarket] = useState<Market | null>(null);
  const navigate = useNavigate(); // Hook for navigation
  const {t} = useTranslationContext();
  const {setWaiterView} = useWaiter();

  // Funzione per ottenere i dati
  useEffect(() => {
    setWaiterView(true);
    myMarketsList().then((resp) => {
        setLegacyMarkets(resp);
        console.log(legacyMarkets)
    }).catch(error => {
      // Gestisci eventuali errori qui
    });
     setWaiterView(false);
  }, []);

  const handleEdit = (market: Market) => {
    // Naviga alla pagina di modifica con l'id o altri dettagli
    navigate(`/market/edit/${market.marketName}`);
  };

  const handleDelete = (market: Market) => {
    setSelectedMarket(market);
    setDeleteModalOpen(true); // Apri il modal di conferma
  };

  const handleCloseModal = () => {
    setDeleteModalOpen(false);
  };

  const confirmDelete = async () => {
    if (selectedMarket) {
      setWaiterView(true);
      mymarketDelete(selectedMarket.id).then((resp) => {
        setLegacyMarkets(resp);
        console.log(legacyMarkets)
      }).catch(error => {
        // Gestisci eventuali errori qui
      });
      setDeleteModalOpen(false);
      setWaiterView(false);
    }
  };

  const handleAddMarket = () => {
    // Naviga alla pagina per aggiungere una nuova attività
    navigate('/market/new');
  };

  return (
    <Box>
      {legacyMarkets && legacyMarkets.length > 0 && legacyMarkets.map((legacyMarket) => (
        <Box key={legacyMarket.nationSedeLegale} sx={{ mb: 4 }}>
          <Typography variant="h6">{legacyMarket.nationSedeLegale}</Typography>
          <Box sx={{ display: 'flex', flexDirection: 'column', overflowX: 'auto' }}>
            {legacyMarket.markets.map((market) => (
              <Box
                sx={{display:'flex', flexDirection:'row'}}
              >
                <Box
                  key={market.marketName}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    borderBottom: '1px solid #ccc',
                    padding: '10px',
                    flex:1,
                    overflowX: 'auto',  // Enable horizontal scrolling
                    maxWidth: 'calc(100% - 100px)',  // Adjust width to ensure it doesn’t overflow due to the buttons
                    whiteSpace: 'nowrap',  // Prevent text wrapping
                  }}
                >
                  <Box sx={{ display: 'flex', flexDirection:'row'}}>
                    <Typography><strong>{market.marketName}</strong></Typography>
                    <Box sx={{display:'flex', flexDirection:'column', paddingLeft:5}}>
                      <Typography
                        sx={{
                          textAlign:'start'
                        }}
                      >{market.marketAddress}, {market.marketCity}, {market.marketCAP}, {market.marketProvince}</Typography>
                      <Typography
                      sx={{
                        fontSize:'0.75rem',
                        textAlign:'start'
                      }}>{market.description}</Typography>
                    </Box>
                  </Box>
                </Box>

                <Box
                  sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', minWidth: '100px' }}
                >
                  <IconButton onClick={() => handleEdit(market)} sx={{ color: 'green' }}>
                    <EditIcon />
                  </IconButton>
                  <IconButton onClick={() => handleDelete(market)} sx={{ color: 'red' }}>
                    <DeleteIcon />
                  </IconButton>
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      ))}


    <Grid container justifyContent="space-around" alignItems="center" spacing={3}>
        <Grid item xs={12} sm={6}>
            <Button variant="contained" color="info" onClick={handleAddMarket} sx={{
                margin:"10px 0 0 0",
                height: '35px',
                color:WHITE
              }}>
                {t("Aggiungi nuova attività")}
            </Button>
         </Grid>
      </Grid>

      <AlertDeleteModal
        open={deleteModalOpen}
        onClose={handleCloseModal}
        onDelete={confirmDelete}
        market={selectedMarket}
      />

    </Box>
  );
};

export default MarketList;
